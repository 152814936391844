import React, { useRef, useState } from 'react'
import { useSession } from '@turnaroundfactor/react';
import { emailService } from '@turnaroundfactor/common';
import axios from 'axios';

export default function EmailInvite({ close, room }) {
  
  const [error, setError] = useState(null);
  const email = useRef(null);
  const { user } = useSession();

  function validateEmail(){
    const emailValid = email.current.checkValidity();
    if ( emailValid === false ) {
      setError('please enter a valid email');
      email.current.classList.add('is-invalid');
      return false;
    }

    return true;
  }

  async function generateMailto(event) {
    event?.preventDefault();
    if ( validateEmail() === false ) return;
    
    setError(null);
    email.current.classList.remove('is-invalid');
    const addr = email.current.value;
    const body = encodeURIComponent(`${user.name} would like you to join them for a meeting. Use the URL below to continue.\n\n${window.location.href}`);

    window.open(`mailto:${addr}?subject=SME Connect: Please join me in this meeting.&body=${body}`, '_blank');
    email.current.value = '';
    
    // Run sendmail endpoint to track invites, but skip email
    try {
      console.log('RUN SEND LINK');
      await axios.post(emailService('send_link'), {
        emailAddress: addr,
        uuid: room,
        send_email: false
      });
    }catch (e) {
      setError('invitation failed, please try later');
      return false;
    }

    close();
    

    return false;
  }

  async function sendInvite(event) {
    event?.preventDefault();
    if ( validateEmail() === false ) return;
    const addr = email.current.value;
    setError(null);
    
    try {
      await axios.post(emailService('send_link'), {
        emailAddress: addr,
        uuid: room,
        send_email: true
      });
    }catch (e) {
      setError('invitation failed, please try later');
      return false;
    }

    close();

  }


  return (
    <div className="mb-4">
      <p>Enter an email address below to invite someone to the room.</p>
      <input type="email" ref={email} className="form-control mb-2" />
      <div className="d-flex flex-column mb-2">
        <button className="btn btn-primary mb-2" onClick={sendInvite}>Send Invite</button>
        <button className="btn btn-secondary" onClick={generateMailto}>Invite with Outlook</button>
      </div>
      { error && <span className="text-danger form-hint">{error}</span>}
    </div>
  );
}