import { useEffect, useState } from "react";
import Collapse from 'react-bootstrap/Collapse';
import Carousel from 'react-bootstrap/Carousel';
import { Room } from '@turnaroundfactor/common';

export default function Invitation({ rooms, joinRoom }) {
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const [invites, setInvites] = useState([]);

  function join(invite) {
    const room = new Room({
      shortname: invite.shortname,
      name: invite.name,
      uuid: invite.uuid
    });

    joinRoom(room);
    setOpen(false);
  }

  function cancel(invite) {
    setInvites(invites.filter(inv => inv.uuid !== invite.uuid))
  }

  function next(){
    if ( index >= invites.length ) return;
    setIndex(index + 1);
  }

  function prev(){
    if ( index <= 0 ) return;
    setIndex(index - 1);
  }

  useEffect(() => {
    // const invites = rooms.filter(invite => {
    //   const delay = new Date(invite.invited_date);
    //   const now   = new Date();
    //   // Two hour delay
    //   delay.setHours(delay.getHours() + 2);
    //   return ( now < delay );
    // }).sort((a, b) => {
    //   return a.invited_date > b.invited_date ? -1 : 1;
    // });

    const list = rooms.sort((a, b) => {
      return a.invited_date > b.invited_date ? -1 : 1;
    });

    setInvites(list);

  }, [rooms]);

  useEffect(() => { 
    setOpen(invites.length > 0);
  }, [invites]);


  return (
    <Collapse in={open}>
      <div className="bg-light pt-4 pb-3 border-bottom text-center">
        <Carousel interval={null} activeIndex={index} controls={false} indicators={false} className="col-md-5 mx-auto">
          {invites.map((invite, idx) => 
            <Carousel.Item key={idx}>
              <div className="bg-dark text-white rounded-lg shadow-sm border p-4 col-md-7 mx-auto text-left text-start">
                <h5 className="text-white">Pending Invitation</h5>
                <p>You've been invited by {invite.invited_by?.name || 'a user'} to join the room <strong>{invite.name}</strong>.</p>
                <div className="btn-group w-100">
                  <button type="button" className="btn btn-primary flex-grow-1 mr-1 me-1" onClick={_ => join(invite)}>Accept</button>
                  <button type="button" className="btn btn-outline-danger flex-grow-1 ml-1 ms-1" onClick={e => cancel(invite)}>Cancel</button>
                </div>
              </div>
            </Carousel.Item>
          )}
        </Carousel>
        <div className="d-inline-flex justify-content-between align-items-center mx-auto">
          { (invites.length > 1) && 
          <button type="button" className="btn btn-link" onClick={prev} disabled={index === 0}>
            <i className="fa fa-fw fa-chevron-left"></i>
            <span className="sr-only">Previous Invitation</span>
          </button> }
          <span className="small d-block text-center py-2">{index + 1} / {invites.length}</span>
          { (invites.length > 1) && 
          <button type="button" className="btn btn-link" onClick={next} disabled={index >= (invites.length - 1)}>
            <i className="fa fa-fw fa-chevron-right"></i>
            <span className="sr-only">Next Invitation</span>
          </button> }
        </div>
      </div>
    </Collapse>
  )

}