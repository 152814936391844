import React, { useState, useEffect } from 'react';
import adapt from '@turnaroundfactor/common';
import RoomInfo from './RoomInfo';
import { getRecentRooms } from './helpers';

export default function RoomList({ joinRoom }){
  const [rooms, setRooms] = useState([]);
  const [isLoading, setIsLoading] = useState([]);

  async function getRooms(){
    setIsLoading(false);
    const data = await getRecentRooms(30);
    setIsLoading(false);
    if ( data ) setRooms(data);
  }

  useEffect(() => {
    getRooms();
    adapt.on('SERVICE_RESTORED', getRooms);

    return _ => {
      adapt.off('SERVICE_RESTORED', getRooms);
    }
  }, []);

  return (
    <>
      <h3 className="mb-3 h4 d-flex flex-row justify-content-between align-items-center">
        Previous Sessions
        <button className="btn btn-sm btn-link " onClick={e => getRooms()}> 
          <i className="fa fa-fw fa-sync-alt mr-1 me-1"></i> 
          refresh
        </button>
      </h3>
      { isLoading === false ? (
        <>
        { rooms.length === 0 ? <p className="text-muted small" data-testid="norooms">No recent sessions found.</p> : 
          <ul className="list-unstyled row mx-md-n2" data-testid="userRooms">
            { rooms?.map((r, i)=> <RoomInfo room={r} key={i} joinRoom={joinRoom} />) }
          </ul>}
        </>
      ) : (<div className="text-center"><i className="fa fa-spinner fa-spin"></i></div>)}
    </>
  )
}