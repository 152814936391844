import adapt from '@turnaroundfactor/common';
import { useSession, useSettings } from '@turnaroundfactor/react';
import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Navigate, useNavigate } from 'react-router-dom';
import logo from '../../assets/logo_white.svg';
import Avatar from '../../components/Avatar';
import UserMenu from '../../components/UserMenu';
import { logLevels } from '../../data/config';
import RoomSelect from './RoomSelect';
import RoomList from './RoomList';
import localStore from '../../utils/localStore';
import getMediaPermissions from '../..//utils/getMediaPermissions';
import Invitation from './Invitation';

export default function Dashboard(){
  const { settings, update: updateSettings } = useSettings();
  const { user, signedIn } = useSession();

  const { prevRoom, logLevel, room, mediaAllowed } = settings;
  const navigate = useNavigate();
  const [invitations, setInvitations] = useState([]);

  function joinRoom(room){
    const data = room && room.serialize ? room.serialize() : room;
    updateSettings({ 
      room: data, 
      prevRoom: null 
    });
    navigate(`/${room.shortname}`);
  }

  async function retryVideo() {
    const { audio, video } = await getMediaPermissions();
    if ( audio === true || video === true ) updateSettings({ mediaAllowed: true });
  }

  function setLogLevel(level) {
    updateSettings({ logLevel: level });
    localStore.set({ logLevel: level });
  }

  useEffect(()=>{
    let isMounted = true;

    async function getRecent(prev){
      if( adapt.room ) await adapt.disconnect();
      const room = await adapt.getRoom(prev);

      if( isMounted === false ) return;      
      if( room !== false ){
        joinRoom(room);
      }
    }

    async function checkPermissions() {
      const { audio, video } = await getMediaPermissions();
      if ( audio === false && video === false ) updateSettings({ mediaAllowed: false });
    }

    checkPermissions();

    if ( user ) {
      setTimeout(_ =>{
        user.refresh().then(data => {
          if ( isMounted ) setInvitations(data.invited_rooms || []);
        })
      }, 1000);
      
    };

    if( prevRoom ) getRecent(prevRoom);
    if( adapt.room ) adapt.disconnect();
    return _ => isMounted = false;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if( user === null || user === undefined || signedIn === false ){
    return <Navigate to="/login" />
  }

  
  return (
    <div id="dashboard" data-testid="dashboard">
        <nav id="toolbar" className="navbar navbar-dark bg-dark py-0 pr-0" data-testid="toolbar">
          <div className="navbar-brand">
            <img src={logo} className="d-block h-100" alt="SME Connect" data-testid="logo" />
          </div>
          <UserMenu user={user} room={room} />
        </nav>
        <div className="p-4 rounded-top shadow-sm" id="dash_user_detail">
            <div className="container">
                <div className="row align-items-center">
                  <div className="col-12 col-md-2 text-center">
                    <Avatar user={user} color="#091930" />
                  </div>
                  <div className="col-12 col-md-8">
                    <h4 className="mb-0 text-primary">Welcome back, {user.name}</h4>
                    <p className="small text-secondary mb-2"><i className="fa fa-user fa-fw"></i> {user.uid}</p>
                    <p className="small text-muted">{user.uuid}</p>

                    <div className="d-flex">
                      <Dropdown className="flex-grow-1">
                        <Dropdown.Toggle variant="dark" className="text-left" size="sm">
                          Log Level: {logLevels[logLevel]}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item className="small" onClick={e => setLogLevel('error')}>Error</Dropdown.Item>
                          <Dropdown.Item className="small" onClick={e => setLogLevel('warn')}>Warning</Dropdown.Item>
                          <Dropdown.Item className="small" onClick={e => setLogLevel('info')}>Info</Dropdown.Item>
                          <Dropdown.Item className="small" onClick={e => setLogLevel('debug')}>Debug</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
            </div>
        </div>        
        {mediaAllowed === false && 
          <div className="container">
          <div className="mt-4 alert alert-danger small d-flex justify-content-between align-items-center">
            <span className="d-inline-block mr-2 me-2">
              <i className="fa fa-exclamation-circle fa-fw mr-1 me-1"></i> 
              Please allow audio and video permissions when joining a room to utilize media features. 
            </span>
          </div>
        </div>}
        <div className="py-4 bg-light border-bottom">
          <div className="container">
            <RoomSelect joinRoom={joinRoom} />
          </div>
        </div>
        <Invitation rooms={invitations} joinRoom={joinRoom} />
        <div className="container pt-4 pt-lg-5">
            <RoomList joinRoom={joinRoom} />
        </div>
    </div>
  )
}